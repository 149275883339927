import React, { useState } from "react";
import { Link } from "gatsby";
import CloudVideo from "./layouts/cloudvideo";
import { imageUrlFor } from "../lib/image-url";
import { InView } from "react-intersection-observer";

const WorkListTwo = ({ what }) => {
  const workList = what;

  const [isBlur, setIsBlur] = useState(false);
  const makeBlur = () => {
    setIsBlur(true);
  };
  const makeClear = () => {
    setIsBlur(false);
  };

  return (
    <>
      <div className="h2Wrap">
        {workList.list.map((work) => (
          <div className={`h2${work.display}`} key={`${work.id}`}>
            <Link to={`/${work.slug.current}`}>
              {work.thumbnail &&
                work.thumbnail.map((c) => {
                  let el = null;
                  switch (c._type) {
                    case "mainImage":
                      el = (
                        <>
                          {/* <InView>
                    {({ inView, ref }) => ( */}
                          <div
                            // ref={ref}
                            className="h2Mod group"
                          >
                            <img
                              key={c._key}
                              loading="eager"
                              onMouseEnter={makeBlur}
                              onMouseLeave={makeClear}
                              className={isBlur ? "postBlur" : "NopostBlur"}
                              src={imageUrlFor(c.asset.url)
                                .auto("format")
                                .height(
                                  Math.floor(c.asset.gatsbyImageData.height / 4)
                                )
                                .width(
                                  Math.floor(c.asset.gatsbyImageData.width / 4)
                                )
                                .quality(100)
                                .dpr(3)
                                .fit("max")
                                .url()}
                              alt={c.alt}
                              height={Math.floor(
                                c.asset.gatsbyImageData.height / 4
                              )}
                              width={Math.floor(
                                c.asset.gatsbyImageData.width / 4
                              )}
                            />
                            <h1 className="h2Title animate-appearTitle">
                              {work.title}
                            </h1>
                          </div>

                          {/* )}
                    </InView> */}
                        </>
                      );
                      break;
                    case "cloudvideo":
                      el = (
                        <InView>
                          {({ inView, ref, entry }) => (
                            <div ref={ref} className="h2Mod group">
                              <div
                                key={c._key}
                                onMouseEnter={makeBlur}
                                onMouseLeave={makeClear}
                                className={isBlur ? "postBlur" : "NopostBlur"}
                              >
                                <CloudVideo alt={c.alt} cloudVideoUrl={c.url} />
                                {inView ? (
                                  <h1 className="h2Title animate-appearTitle">
                                    {work.title}
                                    {/* {inView.toString()} */}
                                  </h1>
                                ) : null}
                              </div>
                            </div>
                          )}
                        </InView>
                      );
                      break;
                    default:
                      el = null;
                  }
                  return el;
                })}
              <div></div>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default WorkListTwo;
