import React from "react";
import { useEffect, useState } from "react";


const BigLogo = ({title}) => {

  const [ playAnimation, setPlayAnimation ] = useState(false);

  useEffect(() => {
    const onPageLoad = () => {

      setPlayAnimation(true);
      const timer = setTimeout(() => removeElement(), 3400);
          console.log('With page load');
          return () => clearTimeout(timer);
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);
        // Doing the visible on the logo
    const [visible, setVisible] = useState(true);

    const removeElement = () => {
      setVisible(!visible);
    };
  

      return ( 
          <>
         { visible && (<p className={ playAnimation ? 'initialLogo' : ''}>{title}</p>)}
          </>
       );
  }
   
  export default BigLogo;