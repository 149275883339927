import React from "react";
import { graphql } from "gatsby";
import Container from "../components/layouts/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import WorkListTwo from "../components/workListTwo";
// import WorkMain from "../components/workMain";
// import WorkFeatured from "../components/workFeatured";
import BigLogo from "../components/navigation/bigLogo";




export const query = graphql`
query WiriQuery {
  site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
    title
    description
    keywords
  }
  posts: allSanityPost(
    filter: {slug: {current: {ne: null}}}
  ) {
    nodes {
      id
      title
      _rawExcerpt
      slug {
        current
      }
      display
      thumbnail {
        ... on SanityCloudvideo {
          _key
          _type
          url
          title
          altTitle
        }
        ... on SanityMainImage {
          _key
          _type
          alt
          caption
          asset {
            _key
            _id
            altText
            url
            title
            gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  }
  index: sanityHome {
    intro
    id
    _key
    list: topNavigation {
      title
      id
      slug {
        _key
        current
      }
      thumbnail {
        ... on SanityCloudvideo {
          _key
          _type
          url
          title
          altTitle
        }
        ... on SanityMainImage {
          _key
          _type
          asset {
            _id
            url
            title
            path
            _type
            id
            description
            gatsbyImageData
          }
          caption
          alt
        }
      }
      display
    }
  }
}
`;

const Home = (props) => {

  const { data, errors } = props;
  console.log("🚀 ~ Home ~ data:", data)
  // const works = data.posts.nodes;
  // console.log(works);
  // const list = data.index.list;
// console.log(list);
  // const MainImage = data.index.main;
// console.log(MainImage);
  // const FeaturedWork = data.index.featured;
// console.log(FeaturedWork);


  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const site = (data || {}).site;
  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <Seo
        title={data.site.title}
        description={data.site.description}
        keywords={data.site.keywords}
      />
      <Container>
        {/* {MainImage && 
       <WorkMain what={data.index.main} /> 
        }
        {FeaturedWork && 
        <WorkFeatured what={FeaturedWork} />
        } */}
        <div className="workListWrapper">
        <WorkListTwo what={data.index} />
        </div>
      </Container>
      <BigLogo className="z-40" title={site.title}/>

    </Layout>
  );
};

export default Home;
